import { React, useEffect, useState,useRef } from "react";
import './addproduct.css'
import { useDrag, useDrop } from "react-dnd";
import ReactQuill from "react-quill";
import {  useLocation, useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import "react-quill/dist/quill.snow.css";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import {uploadImg } from "../../features/upload/uploadSlice";
import {createhistory} from '../../features/auth/authSlice'
import { createProducts, resetState, getAProduct, updateAProduct, getProducts,deleteAProduct, getAllProducts} from "../../features/product/productSlice";
import { getCollections } from "../../features/collection/collectionSlice";
let schema = yup.object().shape({
  title: yup.string().required("Title is Required"),
  handle: yup.string().required("handle is Required"),
  description: yup.string().required("Description is Required"),
  price: yup.number().required("Price is Required"),
  price1: yup.number(),
  category: yup.string().required("Category is Required"),
  brand: yup.string().required("Brand is Required"),
  sku: yup.string().required("SKU is Required"),
  collectionName: yup.string().required("Collection is Required"),
  variants: yup.array().of(
    yup.object().shape({
      color: yup.string(),
      size: yup.string(),
      quantity: yup.number().required('Variant quantity is required')
    })
  ).required('At least one variant is required'),
});



const AddProduct = () => {
  const user=JSON.parse(localStorage.getItem("user"))

  const [colors, setColors] = useState('');
  const [sizes, setSizes] = useState('');
  const [main,setMain]=useState("")
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  const Image = ({ src, id, index, moveImage, deleteImage }) => {
    const ref = useRef(null);
    const [,drop] = useDrop({
      accept: "image",
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) {
          return;
        }
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        moveImage(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });
    const [{ isDragging }, drag] = useDrag({
      type: "image",
      item: () => {
        return { id, index };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
   
    return (
      <div ref={ref} style={{ opacity }} className="image-container">
        <div className="image" style={{ backgroundImage:modifyCloudinaryUrl(src)}} onClick={()=>setMain(src)}>
          <img src={modifyCloudinaryUrl(src)} />
        </div>
        <button className="delete-button" onClick={() => deleteImage(id)}><CloseIcon/></button>

      </div>
    );
  };
  
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  useEffect(() => {
    dispatch(getCollections());
  }, []);
  const getProductId = location.pathname.split("/")[2];
  const imgState = useSelector((state) => state.upload.images);
  const collectionState = useSelector((state) => state.collection.collections);
  const productStat = useSelector((state) => state?.product)
console.log(productStat)
  const {
    isSuccess,
    isError,
    isLoading,
    createdProduct,
    productTitle,
    productAlt,
    productDescription,
    productCategory,
    productImages,
    productPrice,
    productPrice1,
    productHandle,
    productBrand,
    productSku,
    productState,
    productCollectionName,
    productVariants,
    productTags,
    metaDesc,
    metaTitle,
    metaDesc1,
    metaTitle1,
    metaDesc2,
    metaTitle2,
    metaDesc3,
    metaTitle3,
    metaDesc4,
    metaTitle4,
    sold,
    updatedProduct  } = productStat
  useEffect(() => {

    if (getProductId !== undefined) {
      dispatch(getAProduct(getProductId));
      img.push(productImages);
    } else {
      dispatch(resetState());
    }
  }, [getProductId]);
  useEffect(() => {
    dispatch(getCollections());
  }, [dispatch]);

  useEffect(() => {
    if (getProductId) {
      dispatch(getAProduct(getProductId));
    } else {
      dispatch(resetState());
    }
  }, [getProductId, dispatch]);

  useEffect(() => {
    if (productImages) {
      formik.setFieldValue("images", productImages);
    }
  }, [productImages]);



  const img = [];
  imgState.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });
  useEffect(() => {
    formik.values.images = img;
  }, [productImages]);


  const combinedImages = productImages?.length > 0 ? [...productImages, ...imgState] : imgState;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: productTitle || "",
      alt: productAlt || "",
      tags: productTags || "",
      state: productState || "draft",
      handle: productHandle || "",
      description: productDescription || "",
      price: productPrice || '',
      price1: productPrice1 || '',
      category: productCategory || '',
      brand: productBrand || '',
      sku: productSku || '',
      images:combinedImages || imgState,
      collectionName: productCollectionName || '',
      variants: productVariants || [],
      metaDesc:metaDesc||"",
      metaTitle:metaTitle||"",
      metaDesc1:metaDesc1||"",
      metaTitle1:metaTitle1||"",
      metaDesc2:metaDesc2||"",
      metaTitle2:metaTitle2||"",
      metaDesc3:metaDesc3||"",
      metaTitle3:metaTitle3||"",
      metaDesc4:metaDesc4||"",
      metaTitle4:metaTitle4||""
    },

    validationSchema: schema,
    
  });
  const saveProduct=(values) => {
    formik.values.variants = variants
    if (getProductId !== undefined) {
      const product = { id: getProductId, productData: formik.values };
      dispatch(updateAProduct(product));
      dispatch(createhistory({name:user?.firstname,title:productTitle,sku:productSku,productchange:"Updated the Product",time:new Date()}))
    } else {
      dispatch(createProducts(formik.values));
      dispatch(createhistory({name:user?.firstname,title:productTitle,sku:productSku,productchange:"Created the Product",time:new Date()}))

      // setTimeout(() => {
      //   dispatch(getProducts({limit:100,page:1}))
      //   dispatch(resetState());
      // }, 2000);
    }
  }

  useEffect(() => {
    setVariants(productVariants || []);
  }, [productVariants]);

  const handleQuantityChange = (value, index) => {
    setVariants(prevVariants => {
      const updatedVariants = [...prevVariants];
      updatedVariants[index] = { ...updatedVariants[index], quantity: parseInt(value, 10) || 0 };
      return updatedVariants;
    });
  };


  const [variants, setVariants] = useState(productVariants || []);

  const handleVariantChange = (index, field, value) => {
    if(getProductId!==undefined){
      if(user?.firstname==="parul"){
        setVariants((prevVariants) => {
          const updatedVariants = [...prevVariants];
          updatedVariants[index] = { ...updatedVariants[index], [field]: value };
          return updatedVariants;
        });
      }
    }
    else{
      setVariants((prevVariants) => {
        const updatedVariants = [...prevVariants];
        updatedVariants[index] = { ...updatedVariants[index], [field]: value };
        return updatedVariants;
      });
    }
    
  };

  const handleAddVariant = () => {
    if(getProductId!==undefined){
      if(user?.firstname==="parul"){
    setVariants((prevVariants) => [...prevVariants, { color: "", size: "", quantity: 0 }]);


      }}
    else{
      setVariants((prevVariants) => [...prevVariants, { color: "", size: "", quantity: 0 }]);
    }
  };

  const handleDeleteVariant = (index) => {
    if(getProductId!==undefined){
      if(user?.firstname==="parul"){
    setVariants((prevVariants) => prevVariants.filter((_, i) => i !== index));

      }}
      else{
    setVariants((prevVariants) => prevVariants.filter((_, i) => i !== index));

      }

  };
  const moveImage = (dragIndex, hoverIndex) => {
    const draggedImage = formik.values.images[dragIndex];
    const newImages = [...formik.values.images];
    newImages.splice(dragIndex, 1);
    newImages.splice(hoverIndex, 0, draggedImage);
    formik.setFieldValue("images", newImages);
  };

  const deleteImage = (id) => {
    const filteredImages = formik.values.images.filter((image) => image.public_id !== id);
    formik.setFieldValue("images", filteredImages);
  };

  const deleteProduct = () => {
    dispatch(deleteAProduct(getProductId));
    dispatch(createhistory({name:user?.firstname,title:productTitle,sku:productSku,productchange:"Delete the Product",time:new Date()}))
    setTimeout(()=>{
      navigate("/products")
      dispatch(getProducts())

    },1000)
  };
  const duplicateProduct=()=>{
    dispatch(createProducts({
      title: `Copy of - ${productTitle}`,
      tags: productTags,
      state:"draft",
      handle:`${productHandle} -copy`,
      description: productDescription,
      price: productPrice,
      price1: productPrice1,
      category: productCategory,
      brand: productBrand,
      sku: productSku,
      images:combinedImages,
      collectionName: productCollectionName,
      variants: productVariants,
      metaDesc:`${metaDesc}-1`,
      metaTitle:`${metaTitle}-1`,
      metaDesc1:`${metaDesc1}-1`,
      metaTitle1:`${metaTitle1}-1`,
      metaDesc2:`${metaDesc2}-1`,
      metaTitle2:`${metaTitle2}-1`,
      metaDesc3:`${metaDesc3}-1`,
      metaTitle3:`${metaTitle3}-1`,
      metaDesc4:`${metaDesc4}-1`,
      metaTitle4:`${metaTitle4}-1`,
    }));
    dispatch(createhistory({name:user?.firstname,title:productTitle,sku:productSku,productchange:"Duplicate the Product",time:new Date()}))
        setTimeout(() => {
          dispatch(resetState());
        }, 2000);
  }
  const productState1 = useSelector((state) => state?.product?.prdt);

  const [productList, setProductList] = useState([]);

  useEffect(() => {
    dispatch(getAllProducts({limit:100,page:1}))

    
}, [dispatch]);
useEffect(()=>{
        // Assuming the payload.data contains array of product details
        const productIds = productState1?.products?.map(product => product?._id);
        setProductList(productIds);
    
},[productState1])
const { id } = useParams(); // Assuming 'id' is the param name for product ID in the route
const currentProductIndex = productList?.indexOf(id);

const prevProductId = currentProductIndex > 0 ? productList[currentProductIndex - 1] : null;
const nextProductId = currentProductIndex < productList?.length - 1 ? productList[currentProductIndex + 1] : null;
const handlePrevious = () => {
  if (prevProductId) {
      navigate(`/addProduct/${prevProductId}`);
  }
};

const handleNext = () => {
  if (nextProductId) {
      navigate(`/addProduct/${nextProductId}`);
  }
};
const modifyCloudinaryUrl = (url) => {
  const urlParts = url?.split('/upload/');
  return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
};
  return (
    <div className='singlep'>
      <div className="main-image" style={{display:main!==""?"block":'none'}}>
        <img src={modifyCloudinaryUrl(main)} alt="" />
        <p className="close" onClick={()=>setMain("")}><CloseIcon/></p>
      </div>
      <div className="back d-flex my-4 align-items-center">
        {/* <Link to="/products"><IoMdArrowRoundBack style={{ color: 'black', marginRight: '10px', fontSize: '20px' }} /></Link> */}
        <div className="del">
        <p style={{ fontWeight: 500, fontSize: '22px' }}>{formik.values.title}</p>
        <div className="buttons">
          <button style={{display:getProductId!==undefined? "flex":"none"}} onClick={deleteProduct}>Delete</button>
          <button style={{display:getProductId!==undefined? "flex":"none"}} onClick={()=>window.open(`https://voguemine.com/products/${formik.values.handle}`, '_blank')}>View</button>
          <button style={{display:getProductId!==undefined? "flex":"none"}} onClick={duplicateProduct}>Duplicate</button>
          <button onClick={saveProduct}>Save</button>
    {prevProductId && (
        <button onClick={handlePrevious} style={{padding:"0"}}><ChevronLeftIcon/></button>
    )}
    {nextProductId && (
        <button onClick={handleNext} style={{padding:"0"}}><NavigateNextIcon /></button>
    )}
        </div>
        </div>
      </div>
        <div className="mains">
          <div className="left">
            <div className="basic">

              <div className="title">
                <p>Title</p>
                <input type="text"
                  placeholder={formik.values.title}
                  name="title"
                  onChange={formik.handleChange("title")}
                  onBlur={formik.handleBlur("title")}
                  value={formik.values.title} />
              </div>
              <div className="error">
                {formik.touched.title && formik.errors.title}
              </div>
              <div className="title my-2">
                <p>Handle</p>
                <input type="text"
                  placeholder="Enter Product Handle"
                  name="handle"
                  onChange={formik.handleChange("handle")}
                  onBlur={formik.handleBlur("handle")}
                  value={formik.values.handle} />
              </div>
              <div className="error">
                {formik.touched.handle && formik.errors.handle}
              </div>
              <div className="title my-2">
                <p>Price</p>
                <input
                  type="number"
                  placeholder="Enter Product Price"
                  name="price"
                  onChange={formik.handleChange("price")}
                  onBlur={formik.handleBlur("price")}
                  value={formik.values.price} />
              </div>
              <div className="title my-2">
                <p>Chicoline Price</p>
                <input
                  type="number"
                  placeholder="Enter Chicoline Price"
                  name="price1"
                  onChange={formik.handleChange("price1")}
                  onBlur={formik.handleBlur("price1")}
                  value={formik.values.price1} />
              </div>
              <div className="error">
                {formik.touched.price1 && formik.errors.price1}
              </div>
              <div className="desc">
                <p>Description</p>
                <ReactQuill
                  className='descr'
                  theme="snow"
                  name="description"
                  modules={modules}
                  formats={formats}
                  onChange={formik.handleChange("description")}
                  value={formik.values.description}
                />
              </div>
              <div className="error">
                {formik.touched.description && formik.errors.description}
              </div>

            </div>




            <div className="bg-white border-1 p-5 text-center">

            </div>
            <div className="showimages d-flex flex-wrap gap-3">

            </div>



            <div className="media">
              <p>Media</p>
              <div className="imgbox">
    {formik.values.images.map((image, index) => (
      <Image
        key={image?.public_id}
        src={image?.url}
        id={image?.public_id}
        index={index}
        moveImage={moveImage}
        deleteImage={deleteImage}
      />
      
    ))}
    <Dropzone
      onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
      style={{width:'100px',height:'100x',border:'1px solid grey',borderRadius:'5px'}}
     >
      {({ getRootProps, getInputProps }) => (
        <section >
          <div {...getRootProps()} className="img-box-in">
            <input {...getInputProps()} />
            <p className="drag-p">Drag 'n' drop some files here, or click to select files</p>
          </div>
        </section>
      )}
    </Dropzone>
  </div>

            </div>
            <div className="variants">
              <p>Variants</p>
              {variants.map((variant, index) => (
          <div className="variant" key={index}>
            <input
              type="text"
              value={variant.color}
              onChange={(e) => handleVariantChange(index, "color", e.target.value)}
              placeholder="Color"
            />
            <input
              type="text"
              value={variant.size}
              onChange={(e) => handleVariantChange(index, "size", e.target.value)}
              placeholder="Size"
            />
            <input
              type="number"
              value={variant.quantity}
              onChange={(e) => handleVariantChange(index, "quantity", parseInt(e.target.value))}
              placeholder="Quantity"
            />
            <button type="button" onClick={() => handleDeleteVariant(index)}>
              Delete
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddVariant}>
          Add Variant
        </button>
      
            </div>
          </div>
          <div className="right">
            <div className="status">
              <p>Status</p>
              <select
              defaultValue={formik.values.state}
                name="state"
                onChange={formik.handleChange("state")}
                onBlur={formik.handleBlur("state")}
                value={formik.values.state}
                className="form-control py-3 mb-3"
                id="">
                <option value="active">Active</option>
                <option value="draft">Draft</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="insights">
              <div>
                <p>Insights</p>
                <p>Total Sale</p>
              </div>
              <p>{sold} items sold</p>
            </div>
            <div className="p-organization">
              <p>Product Organization</p>
              <div className="title">
                <p>Product Category</p>
                <input type="text"
                  name="category"
                  onChange={formik.handleChange("category")}
                  onBlur={formik.handleBlur("category")}
                  value={formik.values.category}
                  className="form-control py-3 mb-3"
                  id="" />
              </div>
              <div className="title">
                <p>Brand</p>
                <input type="text"
                  placeholder="Enter Product Brand"
                  name="brand"
                  onChange={formik.handleChange("brand")}
                  onBlur={formik.handleBlur("brand")}
                  value={formik.values.brand} />
              </div>
              <div className="title">
                <p>Alt Text</p>
                <input type="text"
                  placeholder="Enter Images Alt"
                  name="alt"
                  onChange={formik.handleChange("alt")}
                  onBlur={formik.handleBlur("alt")}
                  value={formik.values.alt} />
              </div>
              <div className="title">
                <p>SKU</p>
                <input type="text"
                  placeholder="Enter Product SKU"
                  name="sku"
                  onChange={formik.handleChange("sku")}
                  onBlur={formik.handleBlur("sku")}
                  value={formik.values.sku} />
              </div>
              <div className="title">
                <p>Collection</p>
                <select
                  name="collectionName"
                  onChange={formik.handleChange("collectionName")}
                  onBlur={formik.handleBlur("collectionName")}
                  value={formik.values.collectionName}
                  className="form-control py-3 mb-3"
                  id=""
                >
                  <option value="">Select Collection</option>
                  {collectionState.map((i, j) => {
                    return (
                      <option key={j} value={i.title}>
                        {i.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="title">
                <p>Tags</p>
                <input type="text"
                  placeholder="Enter Product Tags"
                  name="tags"
                  onChange={formik.handleChange("tags")}
                  onBlur={formik.handleBlur("tags")}
                  value={formik.values.tags} />
              </div>
              
            </div>
            <div className="metaDetails">
              <p>Voguemine Information</p>
            <div className="title">
                <p>Meta Title</p>
                <input type="text"
                  placeholder="Enter Meta Title"
                  name="metaTitle"
                  onChange={formik.handleChange("metaTitle")}
                  onBlur={formik.handleBlur("metaTitle")}
                  value={formik.values.metaTitle} />
              </div>
              <div className="title">
                <p>Meta Description</p>
                <textarea type="text"
                  placeholder="Enter Meta Description"
                  name="metaDesc"
                  onChange={formik.handleChange("metaDesc")}
                  onBlur={formik.handleBlur("metaDesc")}
                  value={formik.values.metaDesc} />
              </div>
            </div>
            <div className="metaDetails">
              <p>Rampvalk Information</p>
            <div className="title">
                <p>Meta Title</p>
                <input type="text"
                  placeholder="Enter Meta Title"
                  name="metaTitle1"
                  onChange={formik.handleChange("metaTitle1")}
                  onBlur={formik.handleBlur("metaTitle1")}
                  value={formik.values.metaTitle1} />
              </div>
              <div className="title">
                <p>Meta Description</p>
                <textarea type="text"
                  placeholder="Enter Meta Description"
                  name="metaDesc1"
                  onChange={formik.handleChange("metaDesc1")}
                  onBlur={formik.handleBlur("metaDesc1")}
                  value={formik.values.metaDesc1} />
              </div>
             
            </div>
            <div className="metaDetails">
              <p>LevishLuxury Information</p>
            <div className="title">
                <p>Meta Title</p>
                <input type="text"
                  placeholder="Enter Meta Title"
                  name="metaTitle2"
                  onChange={formik.handleChange("metaTitle2")}
                  onBlur={formik.handleBlur("metaTitle2")}
                  value={formik.values.metaTitle2} />
              </div>
              <div className="title">
                <p>Meta Description</p>
                <textarea type="text"
                  placeholder="Enter Meta Description"
                  name="metaDesc2"
                  onChange={formik.handleChange("metaDesc2")}
                  onBlur={formik.handleBlur("metaDesc2")}
                  value={formik.values.metaDesc2} />
              </div>
             
            </div>
            <div className="metaDetails">
              <p>Voguishub Information</p>
            <div className="title">
                <p>Meta Title</p>
                <input type="text"
                  placeholder="Enter Meta Title"
                  name="metaTitle3"
                  onChange={formik.handleChange("metaTitle3")}
                  onBlur={formik.handleBlur("metaTitle3")}
                  value={formik.values.metaTitle3} />
              </div>
              <div className="title">
                <p>Meta Description</p>
                <textarea type="text"
                  placeholder="Enter Meta Description"
                  name="metaDesc3"
                  onChange={formik.handleChange("metaDesc3")}
                  onBlur={formik.handleBlur("metaDesc3")}
                  value={formik.values.metaDesc3} />
              </div>
             
            </div>
            <div className="metaDetails">
              <p>Chicoline Information</p>
            <div className="title">
                <p>Meta Title</p>
                <input type="text"
                  placeholder="Enter Meta Title"
                  name="metaTitle4"
                  onChange={formik.handleChange("metaTitle4")}
                  onBlur={formik.handleBlur("metaTitle4")}
                  value={formik.values.metaTitle4} />
              </div>
              <div className="title">
                <p>Meta Description</p>
                <textarea type="text"
                  placeholder="Enter Meta Description"
                  name="metaDesc4"
                  onChange={formik.handleChange("metaDesc4")}
                  onBlur={formik.handleBlur("metaDesc4")}
                  value={formik.values.metaDesc4} />
              </div>
             
            </div>
          </div>
        </div>
        <div className="submit">
        </div>

      
    </div>
  )
}

export default AddProduct